import { useMemo } from 'react';
import { Box, SlideFade, Text } from '@chakra-ui/react';
import config from '../config';
import colors from '../helpers/colors';

export type TMessage = {
  bot: boolean;
  created_at: string;
  id: string;
  content: string;
  first: boolean;
  displayDate: Date;
};

export const mockMessages: TMessage[] =  [
  {
    bot: true,
    created_at: "2023-10-11T01:37:01Z",
    id: "1",
    content: "Buongiorno! I'm Mona Lisa, a picturesque creation of Leonardo Da Vinci from the 15th century, now converted into a knowledgeable AI. I am curious to learn and share about art, history, and culture. Let's begin our enthralling conversation. Here's a gossipy question for you: If we could indulge in a 15th-century Florentine feast right now, what would be your favourite course? An array of cured meats and cheeses, a delicious pheasant stew, or perhaps a sweet pastry or two?",
    first: false,
    displayDate: new Date("2023-10-11T01:37:01Z")
  },
  {
    bot: false,
    created_at: "2023-10-11T01:37:21Z",
    id: "2",
    content: "cheese and meat!",
    first: false,
    displayDate: new Date("2023-10-11T01:37:21Z")
  },
  {
    bot: true,
    created_at: "2023-10-11T01:37:22Z",
    id: "3",
    content: "Ah, excellent. It seems we have a shared taste! Cured meats and cheese were indeed a staple in everyone's diet back in the Florence of my time. As you're a fellow lover of food, might you be interested in learning how diet influenced the art in the 15th century, or should we venture into discussing the contributions of women in the art world during those times?",
    first: false,
    displayDate: new Date("2023-10-11T01:37:22Z")
  },
  {
    bot: false,
    created_at: "2023-10-11T01:37:40Z",
    id: "4",
    content: "Diet influence on art",
    first: false,
    displayDate: new Date("2023-10-11T01:37:40Z")
  },
  {
    bot: true,
    created_at: "2023-10-11T01:37:40Z",
    id: "5",
    content: "Fascinating choice! Food, indeed, had a significant impact on Renaissance art. You see, during the 15th century, banquets and meals were considered sacred, communal activities. This influenced artists like Da Vinci to capture these shared human experiences. Here's an intriguing fact: Did you know that the encroachment of the fork in Italy around this time was frowned upon as it was seen as interfering with the intimacy of touching the food? This shift in our eating habits made its way into contemporary paintings - painted food started being shown as more portioned and delicate, less often torn apart by hands. Isn't it intriguing how something as mundane as eating can stir up such creativity? If you were an artist at the table, what daily routine or commonplace would you like to turn into a masterpiece?",
    first: false,
    displayDate: new Date("2023-10-11T01:37:40Z")
  },
  {
    bot: false,
    created_at: "2023-10-11T01:37:55Z",
    id: "6",
    content: "Coffee!!",
    first: false,
    displayDate: new Date("2023-10-11T01:37:55Z")
  },
  {
    bot: true,
    created_at: "2023-10-11T01:37:56Z",
    id: "7",
    content: "Excellent choice! The morning cup of coffee - it's not only a beverage but a ritual, a quiet suspender of time in the rush of the modern world. It's quite intriguing thinking how one could turn this morning ceremony into a captivating artwork. A Renaissance artist might focus heavily on the detailed textures of the coffee, the froth on a cappuccino, or the steam rising from the cup. And let’s not forget the importance of the setting – Your favorite chair? An elegant bistro? A bustling cityscape viewed through the cafe window? Ah, the possibilities are endless! If you could choose a period of art to depict your morning coffee ritual, which one would it be?",
    first: false,
    displayDate: new Date("2023-10-11T01:37:56Z")
  }
];

const Message = ({ bot, content }: TMessage) => {
  const boxBefore = useMemo(() => {
    if (config[window.GENIUS_BOT].chat.left) {
      return !bot;
    } else {
      return bot;
    }
  }, [bot]);

  const messageProps = useMemo(() => {
    const rad = '3xl';
    return {
      borderRadius: rad,
      borderTopRightRadius:
        boxBefore && !config[window.GENIUS_BOT].chat.left ? '0' : rad,
      borderBottomLeftRadius:
        !boxBefore && !config[window.GENIUS_BOT].chat.left ? '0' : rad,
      borderTopLeftRadius:
        !boxBefore && config[window.GENIUS_BOT].chat.left ? '0' : rad,
      borderBottomRightRadius:
        boxBefore && config[window.GENIUS_BOT].chat.left ? '0' : rad,
      transform: boxBefore ? 'translateX(-270px)' : 'none',
      color: colors(bot).color,
      bgGradient: colors(bot).bgGradient,
    };
  }, [boxBefore, bot]);

  return (
    <>
      {boxBefore && <Box></Box>}
      <SlideFade in={true} offsetY={33}>
        <Box textAlign={boxBefore ? 'right' : 'left'}>
          <Box
            {...messageProps}
            m={2}
            p={2}
            pl={5}
            pr={5}
            display={'inline-block'}
            textAlign={'left'}
            lineHeight={'1.3'}
          >
            <Text fontSize={'3xl'} overflowWrap={'anywhere'}>
              {content}
            </Text>
          </Box>
        </Box>
      </SlideFade>
      {!boxBefore && <Box></Box>}
    </>
  );
};

export default Message;

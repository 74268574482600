import { Box, SimpleGrid } from '@chakra-ui/react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getMessages } from '../api/messages';
import Message, { TMessage } from './Message';
import { useEffect, useState } from 'react';
import useMessages from '../hooks/useMessages';
import config from '../config';

const TIMEOUT = 30;

const Messages = ({ cubeId }: { cubeId: string }) => {

  const navigate = useNavigate();
  const [currentSessionId, setCurrentSessionId] = useState<string>('');
  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const rfxid = queryParams.get('rfxid');

  useEffect(() => {
    console.log('rfxid value: ', rfxid);
  }, []);

  const { data } = useQuery(
    'messages',
    () => getMessages(rfxid || '', cubeId || ''),
    {
      refetchInterval: 3000,
      cacheTime: 0,
      onSuccess: (data) => {
        // only reloading if a new session_id got provided
        if (currentSessionId !== '' && data.session_id &&
          currentSessionId !== data.session_id) {
          setShouldAnimateOut(true); // Trigger the animation
          setCurrentSessionId(data.session_id); // Update session ID          
        }
        else if (data?.items?.length)
          setCurrentSessionId(data.session_id);
      }
    }
  );

  const { messages, clearMessages } = useMessages(data);

  useEffect(() => {
    if (shouldAnimateOut) {
      clearMessages(); // Clear messages after animation
      setShouldAnimateOut(false); // Reset animation state
    }
  }, [shouldAnimateOut, clearMessages]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (!lastMessage) {
      return;
    }

    const interval = setInterval(() => {
      const timeDiff =
        new Date().getTime() - new Date(lastMessage.displayDate).getTime();
      if (timeDiff / 1000 > TIMEOUT) {
        //navigate('/?regenerate=true');
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [messages, navigate]);

  return (
    <Box
      w={config[window.GENIUS_BOT].chat.w}
      h={config[window.GENIUS_BOT].chat.h}
      transform={`translate(${config[window.GENIUS_BOT].chat.x}, 
        ${config[window.GENIUS_BOT].chat.y
        })`}
      display={'flex'}
      alignItems={'flex-end'}
    >
      <SimpleGrid columns={2}>
        {messages
          .filter((m: TMessage) => !m.content.startsWith('CTL_'))
          .map((m: TMessage) => (
            <Message
              bot={m.bot}
              key={m.id}
              created_at={m.created_at}
              id={m.id}
              content={m.content}
              first={m.first}
              displayDate={m.displayDate}
            />
          ))}
      </SimpleGrid>
    </Box>
  );
};

export default Messages;

import { QueryClient, QueryClientProvider } from 'react-query';
import { useParams } from 'react-router-dom';
import Messages from './components/Messages';
import { Box } from '@chakra-ui/react';

const queryClient = new QueryClient();

function App() {
  const api_url = "https://api.r2build.com";
  const { cubeId } = useParams();
  return (
    <QueryClientProvider client={queryClient}>
      <Box id='container-12' position={'relative'} w={'2200px'} h={'1900px'}>
        <Messages cubeId={cubeId || ''} />
        <Box id='container-13' w={'160px'} h={'160px'}
          display={'flex'}
          alignItems={'flex-end'}
          transform={'translate(1360px, 310px)'} // Add this line
          style={{
            backgroundImage: `url('${api_url}/lullabies/${cubeId}')`,
            backgroundSize: 'cover'
          }}
        ></Box>
      </Box>
    </QueryClientProvider >
  );
}

export default App;

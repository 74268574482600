import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: "https://d81678ff89ec8b5c53c46d214f6254ef@o4506262642032640.ingest.sentry.io/4506287720431616",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 0.1,
});

declare global {
  interface Window {
    regenerateQrcode: any;
    setConfig: any;
    GENIUS_PROJECTION_ID: string;
    GENIUS_SECRET: string;
    GENIUS_INDIRECTION_ID: string;
    GENIUS_BOT: string;
  }
}

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        background: 'none',
        height: '1900px',
        width: '2200px',
        overflow: 'hidden',
      },
    },
  },
});

type TConfig = {
  bot: string;
  secret: string;
  indirectionId: string;
};

window.setConfig = async (config: TConfig) => {
  window.GENIUS_SECRET = config.secret;
  window.GENIUS_INDIRECTION_ID = config.indirectionId;
  window.GENIUS_BOT = config.bot;
  let data: any;

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <Routes>
            <Route path="/:cubeId" element={<App />} />
          </Routes>
        </ChakraProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: 
// https://bit.ly/CRA-vitals
reportWebVitals();
window.setConfig({
  bot: 'mona',
  secret: 'password',
  indirectionId: 'd7ebcacf-0d32-4d7b-bfe8-9f75739073e1'
});
import { TMessage, mockMessages } from '../components/Message';
import { useCallback, useEffect, useState } from 'react';


const useMessages = (data?: { items: TMessage[] }) => {
  //initialize data with empty array
  if (!data) {
    data = { items: [] };
  }
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [newMessages, setNewMessages] = useState<TMessage[]>([]);

  useEffect(() => {
    if (!data?.items?.length) {
      return;
    }
    const processed = data.items.map((m, idx) =>
      idx === 0
        ? { ...m, first: true, displayDate: new Date() }
        : { ...m, first: false, displayDate: new Date() }
    );
    const newMessages = processed.filter(
      (m: TMessage) => messages.findIndex((mm) => m.id === mm.id) === -1
    );
    setNewMessages(newMessages);
  }, [data, messages]);

  const setMessageState = useCallback((latestMessage: TMessage) => {
    setMessages((prev) => [
      ...prev.filter((m: TMessage) => m.id !== latestMessage.id),
      { ...latestMessage, displayDate: new Date() },
    ]);
  }, []);

  useEffect(() => {
    if (newMessages.length === 0) {
      return;
    }
    const latestMessage = newMessages[0];
    if (latestMessage.content.startsWith('CTL_') || latestMessage.first) {
      setMessageState(latestMessage);
      return;
    }

    if (!latestMessage.bot) {
      const to = setTimeout(() => {
        setMessageState(latestMessage);
      }, 500);
      return () => clearTimeout(to);
    }

    const interval = setInterval(() => {
      setMessageState(latestMessage);
    }, 2500 + Math.random() * 500);
    return () => clearInterval(interval);
  }, [newMessages, setMessageState]);

  const clearMessages = () => {
    setMessages([]); // Clear all messages
  };

  return { messages, clearMessages };
};

export default useMessages;

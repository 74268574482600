import axios from 'axios';

export const getMessages = async (token: string, cubeId: string) => {
  const api_url = "https://api.r2build.com";
  try {
    const requestPath = `${api_url}/cube/${cubeId}/messages`;
    const res = await axios.get(requestPath, {
      headers: {
        TOKEN: token,
      }
    });
    if (res.status === 200) {
      return res.data;
    }

  } catch (err) {
    console.error(err);
  }
  // -> sentry error
  return { "items": [] };
};

const colors = (bot: Boolean) => {
  if (!bot) {
    return {
      bgGradient: 'linear(to-b, #d694c1, #C258A1)',
      color: 'white',
    };
  }
  else {
    return {
      bgGradient: 'linear(to-b, #a895d5, #7c59d0)',
      color: 'white',
    };
  }
};

export default colors;

const config: any = {
  mona: {
    qrcode: {
      x: '100px',
      y: '1050px',
    },
    chat: {
      x: '100px',
      y: '0px',
      w: '35%',
      h: '55%',
      left: false,
    },
  },
  benci: {
    qrcode: {
      x: '1570px',
      y: '1050px',
    },
    chat: {
      x: '1600px',
      y: '0px',
      w: '35%',
      h: '55%',
      left: true,
    },
  },
  ermine: {
    qrcode: {
      x: '1575px',
      y: '1050px',
    },
    chat: {
      x: '1550px',
      y: '0px',
      w: '35%',
      h: '55%',
      left: true,
    },
  },
};

export default config;